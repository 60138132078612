import { createApp } from "vue";
import ViewUIPlus from "view-ui-plus";
import "view-ui-plus/dist/styles/viewuiplus.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/index.less";
import Cookies from "js-cookie";
// import i18n from "./libs/i18n/i18";
import { createPinia } from "pinia";
import translate from 'i18n-jsautotranslate'

const app = createApp(App);

const pinia = createPinia();

translate.setUseVersion2() //设置使用v2.x 版本
translate.selectLanguageTag.show = false //是否显示切换栏
translate.listener.start() //监控页面动态渲染的文本进行自动翻译
app.config.globalProperties.$translate = translate;

// import './mock'

app.config.globalProperties.$cookies = Cookies;

app.use(store).use(router).use(ViewUIPlus).use(pinia).mount("#app");
// app.use(store).use(router).use(ViewUIPlus).use(pinia).use(i18n).mount("#app");
