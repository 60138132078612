<template>
  <div class="cert">
    <Tabs :value="tabActive" @on-click="changeTab">
      <TabPane
        v-for="(item, index) in tabList"
        :key="index"
        :label="item.label"
        :name="item.name"
      >
        <Space
          class="searchBox"
          :direction="$store.state.media.isMobile == true ? 'vertical' : 'horizontal'"
        >
          域名：
          <Input
            v-model="pageData.common_name"
            placeholder="请输入主域名"
            :style="$store.state.media.isMobile == true ? 'width:100%' : 'width:300px'"
          />
          <div class="searchBarBtn">
            <Button
              type="primary"
              style="margin-right: 15px"
              icon="ios-search"
              @click="searchSubmit"
              >搜索</Button
            >
          </div>
        </Space>
        <Divider />
        <div class="list" v-if="certList.length != 0">
          <div v-if="$store.state.media.isDesktop == true" class="Pc">
            <div class="list-item" v-for="cert in certList" :key="cert.id">
              <div class="item-top">
                <div class="top-left">订单日期：{{ cert.add_time }}</div>
                <div class="top-right" v-if="cert.status == 1" style="color: blue">
                  {{ cert.status_text }}
                </div>
                <div class="top-right" v-if="cert.status == 2" style="color: green">
                  {{ cert.status_text }}
                </div>
                <div class="top-right" v-if="cert.status == -1" style="color: orange">
                  {{ cert.status_text }}
                </div>
                <div class="top-right" v-if="cert.status == -2" style="color: red">
                  {{ cert.status_text }}
                </div>
              </div>
              <div class="item-center">
                <div class="center-left">
                  <img :src="cert.brand.image" alt="" />
                </div>
                <div class="center-right">
                  <div class="right_top">
                    <div class="right_top_left">{{ cert.product.store_name }}</div>
                    <div class="right_top_right" v-if="cert.status == 2">
                      生效：{{ cert.not_before }}
                    </div>
                    <div class="right_top_right" v-else></div>
                  </div>
                  <div class="right_bottom">
                    <Tooltip :content="cert.common_name" placement="right" theme="light">
                      <div class="right_bottom-left">{{ cert.common_name }}</div>
                    </Tooltip>
                    <div class="right_bottom-right" v-if="cert.status == 2">
                      过期：{{ cert.not_after }}
                    </div>
                    <div class="right_bottom-right" v-else></div>
                  </div>
                </div>
              </div>
              <div class="item-bottom">订单号：{{ cert.orderInfo.order_id }}</div>
              <Divider :dashed="true" />
              <div class="item-footer">
                <Button type="primary" @click="toDetail(cert.orderInfo.order_id)"
                  >查看详情</Button
                >
                <Button
                  type="primary"
                  v-if="cert.status == 2"
                  @click="exoprtCert(cert.id)"
                  >下载证书</Button
                >
              </div>
              <Divider />
            </div>
          </div>
          <div v-else class="notPc">
            <div class="list-item" v-for="cert in certList" :key="cert.id">
              <div class="item-top">
                <div class="top-left">订单日期：{{ cert.add_time }}</div>
                <div class="top-right" v-if="cert.status == 1" style="color: blue">
                  {{ cert.status_text }}
                </div>
                <div class="top-right" v-if="cert.status == 2" style="color: green">
                  {{ cert.status_text }}
                </div>
                <div class="top-right" v-if="cert.status == -1" style="color: orange">
                  {{ cert.status_text }}
                </div>
                <div class="top-right" v-if="cert.status == -2" style="color: red">
                  {{ cert.status_text }}
                </div>
              </div>
              <div class="item-center">
                <div class="center-top">
                  <img :src="cert.brand.image" alt="" />
                  <!-- <img src="@/assets/images/DV.png" alt="" /> -->
                </div>
                <div class="center-content">
                  <div class="content-title">{{ cert.product.store_name }}</div>
                  <Tooltip :content="cert.common_name" placement="right" theme="light">
                    <div class="content-doamin">{{ cert.common_name }}</div>
                  </Tooltip>
                </div>
                <div class="center-bottom">
                  <div class="bottom-time">
                    <div class="time" v-if="cert.status == 2">
                      生效：{{ cert.not_before }}
                    </div>
                    <div class="time" v-else></div>
                  </div>
                  <div class="bottom-time">
                    <div class="time" v-if="cert.status == 2">
                      过期：{{ cert.not_after }}
                    </div>
                    <div class="time" v-else></div>
                  </div>
                </div>
              </div>
              <div class="item-bottom">订单号：{{ cert.orderInfo.order_id }}</div>
              <Divider :dashed="true" />
              <div class="item-footer">
                <div class="item-footer">
                  <Button type="primary" @click="toDetail(cert.orderInfo.order_id)"
                    >查看详情</Button
                  >
                  <Button
                    type="primary"
                    v-if="cert.status == 2"
                    @click="exoprtCert(cert.id)"
                    >下载证书</Button
                  >
                </div>
              </div>
              <Divider />
            </div>
          </div>
          <Page
            :total="total"
            :v-model="pageData.page"
            :page-size="pageData.limit"
            show-total
            class="pageBar"
            @on-change="pageChange"
          />
        </div>
        <div class="empty" v-else>
          <div class="emptyImg">
            <img src="@/assets/images/list_empty.jpg" alt="" />
          </div>
        </div>
      </TabPane>
    </Tabs>
  </div>
</template>

<script setup>
import { certApi } from "@/api/personalCenter";
import { ref, reactive, onMounted, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { Message } from "view-ui-plus";

import {
  iisCertDownApi
} from "@/api/cert";
const { proxy } = getCurrentInstance(); // 获取组件实例

let router = useRouter();
// 分页器
const total = ref(0);
const pageData = reactive({
  page: 1,
  limit: 15,
  status: "",
  common_name: "",
});
const searchValue = ref("");
const tabActive = ref("1");
const tabList = reactive([
  {
    label: "全部明细",
    name: "",
  },
  {
    label: "申请中",
    name: "1",
  },
  {
    label: "已签发",
    name: "2",
  },
  {
    label: "已过期",
    name: "-1",
  },
  {
    label: "已退款",
    name: "-2",
  },
]);
let certList = reactive([]);

// 获取列表
function getList(data) {
  certApi(data).then((res) => {
    if (res.status === 200) {
      proxy.$translate.execute();
      total.value = res.data.count;
      certList.length = 0; // 清空数组
      certList.push(...res.data.list); // 添加新数据到数组
    }
  });
}
const searchSubmit = () => {
  pageData.page = 1;
  pageData.limit = 15;
  getList(pageData);
};
const toDetail = (id) => {
  router.push({
    path: "/certDetail",
    query: {
      id: id,
    },
  });
};
// 下载证书
const exoprtCert = (id) => {  
  let data = {
    id: id,
    key_type: 0,
    action: 'zip',
  };
  iisCertDownApi(data)
    .then((res) => {
      if (res.status == 200) {
        if (res.data.path != "") {
          window.location.href = `https://h.ssl-zs.com/api/download?path=${res.data.path}`;
        }
        Message.success("下载成功");
      }
    })
    .catch((err) => {
      Message.error(err.msg);
    });
};
// 切换数据类型
function changeTab(name) {
  if (name == "") {
    pageData.status = "";
  } else {
    pageData.status = name;
  }
  pageData.page = 1;
  getList(pageData);
}
// 分页器切换
const pageChange = (e) => {
  pageData.page = e;
  getList(pageData);
};

onMounted(() => {
  getList(pageData);
});
</script>

<style lang="less" scoped>
.cert {
  min-height: 700px;
  background-color: #fff;
  .list {
    padding: 15px;
    .Pc {
      .list-item {
        cursor: pointer;
        .item-top {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-size: 16px;
          font-family: SourceHanSansSC-regular;
          .top-left {
            color: #000;
            font-weight: 600;
          }
        }
        .item-center {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          color: #999;
          font-size: 16px;
          font-family: SourceHanSansSC-regular;
          margin: 30px 0;
          .center-left {
            width: 213px;
            height: 40px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .center-right {
            margin-left: 40px;
            width: 100%;
            .right_top,
            .right_bottom {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            }
            .right_top_left {
              color: #000;
              font-weight: 600;
            }
            .right_bottom-left {
              display: flex;
              flex-direction: column;
            }
          }
        }
        .item-bottom,
        .item-footer {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
        }
        Button {
          min-width: 120px;
          height: 38px;
          margin-left: 10px;
        }
      }
    }
    .notPc {
      .list-item {
        cursor: pointer;
        .item-top {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-size: 14px;
          font-family: SourceHanSansSC-regular;
          .top-left {
            color: #000;
            font-weight: 600;
          }
        }
        .item-center {
          width: 100%;
          display: flex;
          flex-direction: column;
          // justify-content: center;
          align-items: center;
          color: #999;
          font-size: 16px;
          font-family: SourceHanSansSC-regular;
          margin: 30px 0 15px;
          .center-top {
            width: 218px;
            height: 40px;
            display: flex;
            align-items: center;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .center-content {
            width: 100%;
            // margin-bottom: 15px;
            margin: 15px 10px;
            .content-title {
              color: #000;
              font-weight: 600;
            }
            .content-doamin {
              width: 100%;
              text-align: right;
            }
          }
          .center-bottom {
            width: 100%;
            text-align: right;
            .time {
              font-size: 14px;
            }
          }
        }
        .item-bottom {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
        }
        .item-footer {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
        }
        Button {
          width: 120px;
          height: 38px;
        }
      }
    }
  }
  .searchBox {
    padding: 10px 15px;
    width: 100%;
  }
  .pageBar {
    text-align: right;
    margin-top: 50px;
  }
}
.empty {
  min-height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  .emptyImg {
    width: 360px;
    height: 275px;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
:deep(.ivu-tabs-nav),
:deep(.ivu-space-item) {
  font-size: 16px !important;
}
</style>
