<template>
  <div :class="$store.state.media.isDesktop == true ? 'info-Pc' : 'info-notPc'">
    <div class="top">
      <Divider v-if="$store.state.media.isDesktop != true" />
      <div class="top-item">
        <div class="lebal">
          <Icon size="16" type="ios-ribbon" />
          <div class="lebal_text">我的昵称</div>
        </div>
        <div class="text" v-if="$store.state.media.isDesktop == true">
          <div class="text_value">{{ userInfo.nickname }}</div>
          <Button type="primary" @click="changeInfoModal('nickname', 1)">修改</Button>
        </div>
        <div class="text" v-else @click="changeInfoModal('nickname', 1)">
          <div class="text_value">{{ userInfo.nickname }}</div>
          <Icon type="ios-arrow-forward" />
        </div>
      </div>
      <Divider />
      <div class="top-item">
        <div class="lebal">
          <Icon size="16" type="ios-cube" />
          <div class="lebal_text">我的账号</div>
        </div>
        <div class="text" v-if="$store.state.media.isDesktop == true">
          <div class="text_value">{{ userInfo.account }}</div>
          <Button type="primary" @click="changeInfoModal('account', 7)">修改</Button>
        </div>
        <div class="text" v-else @click="changeInfoModal('account', 7)">
          <div class="text_value">{{ userInfo.account }}</div>
          <Icon type="ios-arrow-forward" />
        </div>
      </div>
      <Divider />
      <div class="top-item">
        <div class="lebal">
          <Icon size="16" type="md-mail" />
          <div class="lebal_text">我的邮箱</div>
        </div>
        <div class="text" v-if="$store.state.media.isDesktop == true">
          <div class="text_value">{{ userInfo.email }}</div>
          <Button
            type="primary"
            @click="changeInfoModal('email', 2)"
            v-if="userInfo.email != ''"
          >
            修改
          </Button>
          <Button type="primary" @click="changeInfoModal('email', 2)" v-else> 绑定 </Button>
        </div>
        <div class="text" v-else @click="changeInfoModal('email', 2)">
          <div class="text_value">{{ userInfo.email }}</div>
          <Icon type="ios-arrow-forward" />
        </div>
      </div>
      <Divider />
      <div class="top-item">
        <div class="lebal">
          <Icon size="16" type="md-phone-portrait" />
          <div class="lebal_text">我的手机号</div>
        </div>
        <div class="text" v-if="$store.state.media.isDesktop == true">
          <div class="text_value">{{ userInfo.mobile }}</div>
          <Button type="primary" @click="changeInfoModal('mobile', 6)">修改</Button>
        </div>
        <div class="text" v-else @click="changeInfoModal('mobile', 6)">
          <div class="text_value">{{ userInfo.mobile }}</div>
          <Icon type="ios-arrow-forward" />
        </div>
      </div>
      <Divider />
      <div class="top-item">
        <div class="lebal">
          <Icon size="16" type="md-finger-print" />
          <div class="lebal_text">导出证书密码</div>
        </div>
        <div class="text" v-if="$store.state.media.isDesktop == true">
          <div class="text_value">{{ userInfo.pfx_password }}</div>
          <Button type="primary" @click="changeInfoModal('pfx', 3)">修改</Button>
        </div>
        <div class="text" v-else @click="changeInfoModal('pfx', 3)">
          <div class="text_value">{{ userInfo.pfx_password }}</div>
          <Icon type="ios-arrow-forward" />
        </div>
      </div>
      <Divider />
    </div>
    <div class="bottom">
      <div class="bottom-left">
        <div class="left-item">
          <div class="lebal">邮件提醒</div>
          <Switch v-model="userInfo.is_email_push" :before-change="handleBeforeChange" />
          <!-- @on-change="changeStatus($event, 'is_email_push')" -->
        </div>
        <div class="left-item lines">
          <div class="lebal">公众号提醒</div>
          <Switch
            v-model="userInfo.is_wechat_push"
            @on-change="changeStatus($event, 'is_wechat_push')"
          />
        </div>
        <div class="left-item">
          <div class="lebal">小程序提醒</div>
          <Switch
            v-model="userInfo.is_applet_push"
            @on-change="changeStatus($event, 'is_applet_push')"
          />
        </div>
      </div>
      <div class="bottom-right">
        <Button type="error" icon="ios-build" @click="changeInfoModal('pwd', 4)"
          >修改密码</Button
        >
      </div>
    </div>
    <Modal
      v-model="showModal"
      :title="modalTitle"
      :closable="true"
      :mask-closable="false"
      :loading="true"
    >
      <Form
        :model="changeData"
        ref="changeModalForm"
        :rules="ruleInline"
        label-position="right"
        :label-width="100"
      >
        <FormItem label="昵称" prop="nickname" v-if="typeNumber == 1">
          <Input v-model="changeData.nickname"></Input>
        </FormItem>
        <FormItem label="邮箱" prop="email" v-if="typeNumber == 2">
          <Input v-model="changeData.email" placeholder="请输入邮箱"></Input>
        </FormItem>
        <FormItem label="验证码" prop="code" v-if="typeNumber == 2">
          <div class="code">
            <Input v-model="changeData.code"></Input>
            <Button
              class="getCode"
              type="primary"
              :disabled="isSending"
              @click="mailCodeSubmit"
              >{{ sendText }}</Button
            >
          </div>
        </FormItem>
        <FormItem label="证书密码" prop="fpx_password" v-if="typeNumber == 3">
          <Input v-model="changeData.fpx_password"></Input>
        </FormItem>
        <FormItem label="原始密码" prop="oldPassword" v-if="typeNumber == 4">
          <Input type="password" v-model="changeData.oldPassword"></Input>
        </FormItem>
        <FormItem label="新密码" prop="newPassword" v-if="typeNumber == 4">
          <Input type="password" v-model="changeData.newPassword"></Input>
        </FormItem>
        <FormItem label="确认新密码" prop="fixNewPassword" v-if="typeNumber == 4">
          <Input type="password" v-model="changeData.fixNewPassword"></Input>
        </FormItem>
        <FormItem label="手机号码" prop="mobile" v-if="typeNumber == 6">
          <Input type="text" v-model="changeData.mobile"></Input>
        </FormItem>
        <FormItem label="账号" prop="account" v-if="typeNumber == 7">
          <Input type="text" v-model="changeData.account"></Input>
        </FormItem>
      </Form>
      <template #footer>
        <div class="footerBtn">
          <Button type="text" size="large" @click="cancel">取消</Button>
          <Button type="primary" size="large" @click="modalCheckBtn">确定</Button>
        </div>
      </template>
    </Modal>
  </div>
</template>

//
<script setup>
import {
  userInfoApi,
  changeAccountApi,
  changePwdApi,
  getChangeEmailCodeApi,
  changeEmailApi,
  changePfxPwdApi,
  changeMsgPushStatusApi,
  changeNicknameApi,
  changeMobileApi,
} from "@/api/personalCenter";
import { ref, onMounted, reactive, getCurrentInstance } from "vue";
import { Message } from "view-ui-plus";

let userInfo = reactive({
  account: "",
  email: "",
  is_applet_push: false,
  is_email_push: false,
  is_wechat_push: false,
  mobile: "",
  nickname: "",
  now_money: "",
  pfx_password: "",
});

function getUserInfo() {
  userInfoApi().then((res) => {
    if (res.status == 200) {
      const data = res.data.info;
      data.is_applet_push = data.is_applet_push == 0 ? false : true;
      data.is_email_push = data.is_email_push == 0 ? false : true;
      data.is_wechat_push = data.is_wechat_push == 0 ? false : true;
      Object.assign(userInfo, data);
    }
  });
}

// -------------修改信息部分开始-----------
const { proxy } = getCurrentInstance(); // 获取组件实例
let showModal = ref(false); //弹窗开关状态
let modalTitle = ref(""); // 弹窗标题
let typeNumber = ref(0); // 弹窗表单显示内容区分
let sendText = ref("获取验证码");
let isSending = ref(false);
const counter = ref(0);
let timer = null;
let changeData = reactive({
  nickname: "",
  email: "",
  code: "",
  fpx_password: "",
  oldPassword: "",
  newPassword: "",
  fixNewPassword: "",
  mobile: "",
  account: "",
});
const ruleInline = {
  nickname: [{ required: true, message: "请输入昵称", trigger: "blur" }],
  email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
  code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
  mobile: [{ required: true, message: "请输入手机号码", trigger: "blur" }],
  fpx_password: [{ required: true, message: "请输入证书密码", trigger: "blur" }],
  oldPassword: [{ required: true, message: "请输入旧密码", trigger: "blur" }],
  newPassword: [{ required: true, message: "请输入新密码", trigger: "blur" }],
  fixNewPassword: [{ required: true, message: "请确认新密码", trigger: "blur" }],
  // code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
};
const changeInfoModal = (dataType, typeNum) => {
  showModal.value = true;
  typeNumber.value = typeNum;
  if (dataType == "nickname") {
    modalTitle.value = "修改昵称";
    changeData.nickname = userInfo.nickname;
  } else if (dataType == "email") {
    modalTitle.value = "修改邮箱";
    changeData.email = userInfo.email;
  } else if (dataType == "pfx") {
    modalTitle.value = "修改导出证书密码";
    changeData.fpx_password = userInfo.pfx_password;
  } else if (dataType == "pwd") {
    modalTitle.value = "修改账户密码";
  } else if (dataType == "mobile") {
    changeData.mobile = userInfo.mobile;
    modalTitle.value = "修改手机号码";
  } else if (dataType == "account") {
    changeData.account = userInfo.account;
    modalTitle.value = "修改账号";
  }
};
// 修改推送消息状态
const changeStatus = (e, type) => {
  let data = {
    type: type,
    status: e,
  };
  changeMsgPushStatus(data);
};
const handleBeforeChange = () => {
  if (userInfo.email == "") {
    return new Promise((resolve) => {
      changeInfoModal("email", 2);
    });
  } else {
    let data = {
      type: "is_email_push",
      status: !userInfo.is_email_push,
    };
    changeMsgPushStatus(data);
  }
};
// 编辑弹窗确定按钮
const modalCheckBtn = () => {
  proxy.$refs.changeModalForm.validate((valid) => {
    if (valid) {
      if (typeNumber.value == 1) {
        let data = {
          nickname: changeData.nickname,
        };
        changeNickname(data);
      } else if (typeNumber.value == 2) {
        let data = {
          email: changeData.email,
          code: changeData.code,
        };
        changeEmail(data);
      } else if (typeNumber.value == 3) {
        let data = {
          fpx_password: changeData.fpx_password,
        };
        changePfxPwd(data);
      } else if (typeNumber.value == 4) {
        let data = {
          oldPassword: changeData.oldPassword,
          newPassword: changeData.newPassword,
          fixNewPassword: changeData.fixNewPassword,
        };
        changePwd(data);
      } else if (typeNumber.value == 6) {
        let data = {
          mobile: changeData.mobile,
        };
        changeMobile(data);
      } else if (typeNumber.value == 7) {
        let data = {
          account: changeData.account,
        };
        changeAccount(data);
      }
    } else {
      return false;
    }
  });
};
// 编辑弹窗取消按钮
const cancel = () => {
  showModal.value = false;
};

// 发送按钮倒计时-开始
const startCountdown = () => {
  if (counter.value > 0) {
    counter.value--;
    sendText.value = `${counter.value} 秒后重新发送`;
  } else {
    stopCountdown();
  }
};
// 发送按钮倒计时-结束
const stopCountdown = () => {
  if (timer !== null) {
    clearInterval(timer);
    timer = null;
    isSending.value = false;
    counter.value = 60;
    sendText.value = "重新发送验证码";
  }
};
// 获取邮箱验证码按钮
const mailCodeSubmit = (type) => {
  if (isSending.value) return;

  // 模拟发送验证码
  isSending.value = true;
  sendText.value = `${counter.value} 秒后重新发送`;
  let data = {
    email: changeData.email,
  };
  getChangeEmailCode(data);

  // 启动倒计时
  counter.value = 60;
  timer = setInterval(startCountdown, 1000);
};

// ---请求接口---
// 修改密码请求
const changPassword = (data) => {
  changePwdApi(data)
    .then((res) => {
      if (res.status == 200) {
        Message.success(res.msg || "修改成功");
        showModal.value = false;
      }
    })
    .catch((res) => {
      Message.error(res.msg);
    });
};
// 修改账号
const changeAccount = (data) => {
  changeAccountApi(data)
    .then((res) => {
      if (res.status == 200) {
        Message.success(res.msg || "修改成功");
        userInfo.account = changeData.account;
        showModal.value = false;
      }
    })
    .catch((res) => {
      Message.error(res.msg);
    });
};
// 改变推送消息状态请求
const changeMsgPushStatus = (data) => {
  changeMsgPushStatusApi(data)
    .then((res) => {
      if (res.status == 200) {
        Message.success(res.msg || "状态修改成功");
        showModal.value = false;
      }
    })
    .catch((res) => {
      Message.error(res.msg);
    });
};
// 修改昵称
const changeNickname = (data) => {
  changeNicknameApi(data)
    .then((res) => {
      if (res.status == 200) {
        Message.success(res.msg || "修改成功");
        userInfo.nickname = changeData.nickname;
        showModal.value = false;
      }
    })
    .catch((res) => {
      Message.error(res.msg);
    });
};
// 修改证书密码
const changePfxPwd = (data) => {
  changePfxPwdApi(data)
    .then((res) => {
      if (res.status == 200) {
        Message.success(res.msg || "修改成功");
        userInfo.pfx_password = changeData.fpx_password;
        showModal.value = false;
      }
    })
    .catch((res) => {
      Message.error(res.msg);
    });
};
// 修改账号秘密
const changePwd = (data) => {
  changePwdApi(data)
    .then((res) => {
      if (res.status == 200) {
        Message.success(res.msg || "修改成功");
        showModal.value = false;
      }
    })
    .catch((res) => {
      Message.error(res.msg);
    });
};
// 修改手机号码
const changeMobile = (data) => {
  changeMobileApi(data)
    .then((res) => {
      if (res.status == 200) {
        Message.success(res.msg || "修改成功");
        showModal.value = false;
      }
    })
    .catch((res) => {
      Message.error(res.msg);
    });
};
// 获取邮箱验证码
const getChangeEmailCode = (data) => {
  getChangeEmailCodeApi(data)
    .then((res) => {
      if (res.status == 200) {
        Message.success(res.msg || "验证码发送成功");
      }
    })
    .catch((res) => {
      Message.error(res.msg);
      stopCountdown();
    });
};
// 修改邮箱
const changeEmail = (data) => {
  changeEmailApi(data)
    .then((res) => {
      if (res.status == 200) {
        Message.success(res.msg || "修改成功");
        userInfo.email = changeData.email;
        showModal.value = false;
      }
    })
    .catch((res) => {
      Message.error(res.msg);
    });
};
// -------------修改信息部分结束-----------

onMounted(getUserInfo);
defineExpose({
  changeInfoModal,
  changeStatus,
  mailCodeSubmit,
  modalCheckBtn,
  changeModalForm: proxy.$refs.changeModalForm,
});
</script>

<style lang="less" scoped>
.info-Pc {
  min-height: 700px;
  background-color: #fff;
  .top-item {
    display: flex;
    flex-direction: row;
    padding: 20px 10px;
    .lebal {
      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;
      width: 15%;
      min-width: 130px;

      .lebal_text {
        margin-left: 10px;
        font-size: 16px;
      }
    }
    .text {
      margin-left: 30px;
      width: 90%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      Button {
        width: 120px;
        height: 32px;
      }
    }
  }
  .bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 10px;
    .bottom-left {
      display: flex;
      flex-direction: row;
    }
    .left-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-items: center;
      .lebal {
        margin-right: 15px;
      }
      position: relative;
    }
    Button {
      min-width: 120px;
      height: 32px;
    }
    .lines {
      padding: 0 20px;
      margin: 0 15px;
    }
    .lines::after {
      content: "";
      position: absolute;
      top: 50%;
      width: 1px;
      left: 100%;
      height: 100%;
      background-color: #e4e3e3;
      transform: translate(-50%, -50%);
    }
    .lines::before {
      content: "";
      position: absolute;
      top: 50%;
      width: 1px;
      right: 100%;
      height: 100%;
      background-color: #e4e3e3;
      transform: translate(-50%, -50%);
    }
  }
}
.info-notPc {
  min-height: 650px;
  background-color: #fff;

  .top-item {
    display: flex;
    flex-direction: row;
    padding: 15px 10px;
    .lebal {
      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;
      width: 15%;
      min-width: 130px;

      .lebal_text {
        margin-left: 10px;
        font-size: 16px;
      }
    }
    .text {
      margin-left: 10px;
      width: 90%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      Button {
        width: 120px;
        height: 32px;
      }
    }
  }
  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 10px;
    .bottom-left {
      display: flex;
      flex-direction: column;
    }
    .left-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-items: center;
      margin-bottom: 15px;
      .lebal {
        margin-right: 15px;
        min-width: 70px;
      }
      position: relative;
    }
    Button {
      width: 150px;
      height: 45px;
      margin-top: 30px;
    }
  }
  :deep(.ivu-divider-horizontal) {
    margin: 12px 0;
  }
}
.code {
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    margin-left: 5px;
  }
}
.footerBtn {
  button {
    font-size: 14px;
    height: 32px;
  }
}
</style>
