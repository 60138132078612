import request from "@/libs/request";

// 个人中心 - 首页 信息
export function dashboardApi() {
  return request({
    url: "/user/dashboard",
    method: "get",
  });
}

// ------------------------------------
// 个人中心 - 首页  退出登录
export function logoutApi() {
  return request({
    url: "/logout",
    method: "get",
  });
}
// 获取批量下单的产品列表
export function userProductApi() {
  return request({
    url: "/user/get_user_product",
    method: "get",
  });
}
// 批量下单
export function bulkOrderApi(data) {
  return request({
    url: "/order/bulk_order",
    method: "post",
    data
  });
}
// 获取批量导出参数
export function userProductParamsApi() {
  return request({
    url: "/user/get_user_product_pl",
    method: "get",
  });
}
// 批量导出
export function bulkExportApi(data) {
  return request({
    url: "/user/export_bulk",
    method: "post",
    data
  });
}
// ------------------------------------
// 个人中心 - 账号管理  用户信息
export function userInfoApi() {
  return request({
    url: "/user/userinfo",
    method: "get",
  });
}
// 修改账号密码
export function changePwdApi(data) {
  return request({
    url: "/user/edit_password",
    method: "post",
    data,
  });
}
// 修改账号
export function changeAccountApi(data) {
  return request({
    url: "/user/edit_account",
    method: "post",
    data,
  });
}
// 发送修改邮箱验证码
export function getChangeEmailCodeApi(data) {
  return request({
    url: "/user/send_email_code",
    method: "post",
    data,
  });
}
// 修改邮箱
export function changeEmailApi(data) {
  return request({
    url: "/user/edit_email",
    method: "post",
    data,
  });
}
// 修改证书密码
export function changePfxPwdApi(data) {
  return request({
    url: "/user/edit_fpx_password",
    method: "post",
    data,
  });
}
// 改推送消息状态
export function changeMsgPushStatusApi(data) {
  return request({
    url: "/user/edit_message_push",
    method: "post",
    data,
  });
}
// 修改昵称
export function changeNicknameApi(data) {
  return request({
    url: "/user/edit_nickname",
    method: "post",
    data,
  });
}
// 修改手机号码
export function changeMobileApi(data) {
  return request({
    url: "/user/edit_mobile",
    method: "post",
    data,
  });
}
// ------------------------------------
// 个人中心 - 我的余额
export function balanceApi(data) {
  return request({
    url: "/user/bill_list",
    method: "get",
    params: data,
  });
}
// 余额充值
export function rechargeApi(data) {
  return request({
    url: "/user/recharge",
    method: "post",
    data,
  })
}
// 查询余额充值状态
export function checkYeStatusApi(data) {
  return request({
    url: `/user/check_order_status`,
    method: "get",
    params:data,
  });
}
// ------------------------------------
// 个人中心 - 我的证书列表
export function certApi(data) {
  return request({
    url: "/user/cert_list",
    method: "get",
    params: data,
  });
}
// 申请证书
export function certApplyApi(data) {
  return request({
    url: "/order/apply_cert",
    method: "post",
    data,
  });
}
// 检查dcv
export function checkDcvApi(data) {
  return request({
    url: "/order/simulate_dcv",
    method: "get",
    params: data,
  })
}
// 切换验证方式
export function changeDcvApi(data) {
  return request({
    url: "/order/change_dcv",
    method: "get",
    params: data,
  })

}// 通知CA检查
export function noticeCACheckApi(data) {
  return request({
    url: `/order/check_dcv/${data.id}`,
    method: "get"
  });
}
 
// ------------------------------------
// 个人中心 - 我的订单
export function orderApi(data) {
  return request({
    url: "/user/order_list",
    method: "get",
    params: data,
  });
}
// 订单详情
export function orderDetailApi(data) {
  return request({
    url: `/order/order_detail/${data.id}`,
    method: "get",
  });
}

// ------------------------------------
// 个人中心 - 公司管理
export function companyApi(data) {
  return request({
    url: "/user/company_list",
    method: "get",
    params: data,
  });
}
// 获取单个公司详情
export function companyDetailApi(data) {
  return request({
    url: `/user/company_read/${data.id}`,
    method: "get",
  });
}
// 添加或编辑公司信息
export function companySaveApi(data) {
  return request({
    url: "/user/company_add",
    method: "post",
    data,
  });
}

// 删除单个公司
export function companyDeleteApi(data) {
  return request({
    url: `/user/company_delete/${data.id}`,
    method: "delete",
  });
}
// ------------------------------------
// 个人中心 - API密钥
export function accessKeyApi(data) {
  return request({
    url: "/user/access_key_list",
    method: "get",
    params: data,
  });
}
// 设置备注
export function accessKeyLabelApi(data) {
  return request({
    url: "/user/access_key_label",
    method: "post",
    data,
  });
}
// 设置白名单
export function accessKeyIpWhiteApi(data) {
  return request({
    url: "/user/access_key_ip_whitelist",
    method: "post",
    data,
  });
}
// 生成密钥
export function accessKeyCreatApi(data) {
  return request({
    url: "/user/create_access_key",
    method: "post",
    data,
  });
}
// 删除单个密钥
export function accessKeyDeleteApi(data) {
  return request({
    url: `/user/delete_access_key/${data.id}`,
    method: "delete",
  });
}
// -------------------------------------
