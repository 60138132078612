<template>
  <div :class="$store.state.media.isDesktop == true ? 'apiBox-Pc' : 'apiBox-notPc'">
    <div class="title">
        
    </div>
  </div>
</template>

<script setup>
</script>

<style lang="less" scoped>
</style>
