// 证书详情
<template>
  <div :class="$store.state.media.isDesktop == true ? 'certInfo-Pc' : 'certInfo-notPc'">
    <div class="routerBox">
      <Breadcrumb separator=">">
        <BreadcrumbItem key="1" to="/" class="breadItem"> 首页 </BreadcrumbItem>
        <BreadcrumbItem key="2" to="/certInfo" class="breadItem">
          产品详情
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
    <!-- pc -->
    <div class="certTip" v-if="$store.state.media.isDesktop == true">
      <div class="tip-left">
        <img :src="info.brand_image" alt="" />
        <!-- <img src="@/assets/images/DV.png" alt="" /> -->
        <div>{{ info.store_name }}</div>
      </div>
      <div class="tip-right">
        <!-- <img src="@/assets/images/personal_bg.jpg" alt="" /> -->
        <!-- <img src="@/assets/images/DV.png" alt="" /> -->
        <img src="https://static.rjxssl.com/img/personal_bg.15217e1a.jpg" alt="" />
      </div>
    </div>
    <!-- h5 -->
    <div class="certTip" v-if="$store.state.media.isDesktop != true">
      <div class="tip-left">
        <!-- <img :src="itm.brand_image" alt="" /> -->
        <img src="@/assets/images/DV.png" alt="" />
        <div>{{ info.store_name }}</div>
      </div>
    </div>
    <div class="detail notpc">
      <div class="item">
        证书价格：<span>￥{{ info.price }}</span
        >/年
      </div>
      <div class="item line1" v-if="info.validation_level == 0">验证级别：DV</div>
      <div class="item line1" v-if="info.validation_level == 1">验证级别：OV</div>
      <div class="item line1" v-if="info.validation_level == 2">验证级别：EV</div>
    </div>
    <div class="detail">
      <div v-html="info.description"></div>
    </div>
    <div class="bugNow">
      <Button class="button" type="primary" @click="bugNow(info)">立即购买</Button>
    </div>
    <orderDrawer ref="orderDrawerRef"></orderDrawer>
  </div>
</template>

<script setup>
import { getProductDetailsApi } from "@/api/order";
import { ref, reactive, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import orderDrawer from "@/components/orderDrawer";

let route = useRoute();
let info = reactive({});
const props = defineProps(["store"]);
const metaTags = getInitialMetaTags();
let store = props.store;

let orderDrawerRef = ref(null);

const getProductDetails = (data) => {
  getProductDetailsApi(data).then((res) => {
    if (res.status == 200) {
      Object.assign(info, res.data.info);
      info.description = info.description.replace(
        /<img/gi,
        '<img style="max-width:100%;height:auto;display:inline-block;margin:0px auto;"'
      );
      info.description = info.description.replace(
        /<table/gi,
        '<table style="width:100%"'
      );
      document.title = info.store_name;
      let periods_price_txt = [];
      periods_price_txt.push(...res.data.info.periods_price_txt);
      periods_price_txt.forEach((item) => {
        if (item.name_un == info.default) {
          info.price = item.price;
        }
      });
    }
  });
};
const bugNow = (item) => {
  orderDrawerRef.value.openDrawer(item);
};

// ===============处理网页标题，关键词，描述================
function getInitialMetaTags() {
  const title = document.title;
  return { title };
}
onMounted(() => {
  getProductDetails({ store_slug: store });
});
</script>

<style lang="less" scoped>
.certInfo-Pc {
  padding: 25px 5% 40px;
  .certTip {
    // padding: 27px 30px 31px 64px;
    font-size: 28px;
    color: #243f5d;
    background-color: #dfeafc;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .tip-left {
      margin: 20px 30px;
    }
    .tip-right {
      width: 480px;
      height: auto;
      // min-height: 150px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .detail {
    width: 100%;
    // height: 182px;
    background-color: #fff;
    padding: 31px 30px;
    .item {
      font-size: 16px;
      span {
        color: #e93323;
      }
      .cert_price {
        color: #e93323;
      }
      & ~ .item {
        margin-top: 12px;
      }
    }
  }
  .bugNow {
    padding: 20px 30px;
    background-color: #fff;
    margin-top: 20px;
    text-align: right;
  }
}

.certInfo-notPc {
  padding: 25px 5% 20px;
  .certTip {
    // padding: 27px 30px 31px 64px;
    font-size: 28px;
    color: #243f5d;
    background-color: #dfeafc;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .tip-left {
      margin: 20px 30px;
    }
    .tip-right {
      width: 480px;
      height: 150px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .detail {
    width: 100%;
    overflow: auto;
    background-color: #fff;
    padding: 12px 5px;
    .item {
      font-size: 16px;
      span {
        color: #e93323;
      }
      .cert_price {
        color: #e93323;
      }
      & ~ .item {
        margin-top: 12px;
      }
    }
  }
  .notpc{
    padding: 8px 13px;
  }
  .bugNow {
    padding: 20px 30px;
    background-color: #fff;
    margin-top: 20px;
    text-align: center;
  }
}
.routerBox {
  margin-bottom: 15px;
}
</style>
