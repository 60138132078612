<template>
  <div>
    <Drawer
      :title="drawerTitle"
      :z-index="1006"
      :closable="true"
      v-model="isShowDrawer"
      :mask-style="{
        'z-index': '1003',
      }"
      class-name="drawerBox"
      :width="drawerWidth"
    >
      <Form :model="formData" :rules="ruleInline" ref="companyForm" :label-width="100">
        <FormItem label="公司名称" prop="organization">
          <Input v-model="formData.organization" placeholder="请输入公司名称"></Input>
        </FormItem>
        <FormItem label="部门" prop="organization_unit">
          <Input v-model="formData.organization_unit" placeholder="请输入部门"></Input>
        </FormItem>
        <FormItem label="三证合一号" prop="serial_no">
          <Input v-model="formData.serial_no" placeholder="请输入三证合一号"></Input>
        </FormItem>
        <FormItem label="成立时间" prop="date_of_incorporation">
          <DatePicker
            type="date"
            placeholder="请选择成立时间"
            style="width: 100%"
          ></DatePicker>
          <!-- <DatePicker type="date" placeholder="Select date" /> -->
          <!-- @on-change="dateChange" -->
        </FormItem>
        <FormItem label="国家" prop="country" >
          <Select v-model="formData.country" placeholder="请选择国家">
            <Option value="beijing">New York</Option>
            <Option value="shanghai">London</Option>
            <Option value="shenzhen">Sydney</Option>
            <Option value="CN">中国</Option>
            <Option value="HK">中国香港</Option>
            <Option value="TW">中國臺灣</Option>
            <Option value="US">美国</Option>
            <Option value="AX">Åland</Option>
            <Option value="ME">Montenegro</Option>
            <Option value="RS">Serbia</Option>
            <Option value="MO">Macau</Option>
            <Option value="AG">Antigua and Barbuda</Option>
            <Option value="AF">Afghanistan</Option>
            <Option value="AZ">Azerbaijan</Option>
            <Option value="AW">Aruba</Option>
            <Option value="AE">الامارات</Option>
            <Option value="AM">Armenia</Option>
            <Option value="AO">Angola</Option>
            <Option value="AD">Andorra</Option>
            <Option value="AI">Anguilla</Option>
            <Option value="BB">Barbados</Option>
            <Option value="BM">Bermuda</Option>
            <Option value="BW">Botswana</Option>
            <Option value="BS">The Bahamas</Option>
            <Option value="DZ">Algeria</Option>
            <Option value="AL">Shqipëri</Option>
            <Option value="BZ">Belize</Option>
            <Option value="AR">Argentina</Option>
            <Option value="BA">Bosna i Hercegovina</Option>
            <Option value="SB">Solomon Islands</Option>
            <Option value="BR">Brasil</Option>
            <Option value="AS">American Samoa</Option>
            <Option value="BY">Belarus</Option>
            <Option value="AU">Australia</Option>
            <Option value="AT">Österreich</Option>
            <Option value="BG">Bulgaria</Option>
            <Option value="BH">البحرين</Option>
            <Option value="CG">Congo</Option>
            <Option value="BE">België</Option>
            <Option value="BD">Bangladesh</Option>
            <Option value="LK">Sri Lanka</Option>
            <Option value="BO">Bolivia</Option>
            <Option value="BJ">Benin</Option>
            <Option value="CC">Cocos (Keeling) Islands</Option>
            <Option value="MM">Myanmar</Option>
            <Option value="KY">Cayman Islands</Option>
            <Option value="BT">Bhutan</Option>
            <Option value="CM">Cameroon</Option>
            <Option value="BN">Brunei</Option>
            <Option value="CO">Colombia</Option>
            <Option value="CA">Canada</Option>
            <Option value="TD">Chad</Option>
            <Option value="BI">Burundi</Option>
            <Option value="CF">Central African Republic</Option>
            <Option value="KH">Cambodia</Option>
            <Option value="CD">Democratic Republic of Congo</Option>
            <Option value="CV">Cape Verde</Option>
            <Option value="DK">Danmark</Option>
            <Option value="EC">Ecuador</Option>
            <Option value="IE">Ireland</Option>
            <Option value="EG">مصر</Option>
            <Option value="GQ">Equatorial Guinea</Option>
            <Option value="ER">Eritrea</Option>
            <Option value="ET">Ethiopia</Option>
            <Option value="CZ">Česká republika</Option>
            <Option value="FM">Federated States of Micronesia</Option>
            <Option value="PF">French Polynesia</Option>
            <Option value="FR">France</Option>
            <Option value="CL">Chile</Option>
            <Option value="GA">Gabon</Option>
            <Option value="GE">Georgia</Option>
            <Option value="KM">Comoros</Option>
            <Option value="MP">Northern Mariana Islands</Option>
            <Option value="GD">Grenada</Option>
            <Option value="CR">Costa Rica</Option>
            <Option value="GL">Greenland</Option>
            <Option value="CU">Cuba</Option>
            <Option value="CK">Cook Islands</Option>
            <Option value="GU">Guam</Option>
            <Option value="GT">Guatemala</Option>
            <Option value="GY">Guyana</Option>
            <Option value="DJ">Djibouti</Option>
            <Option value="GR">Ellás</Option>
            <Option value="DM">Dominica</Option>
            <Option value="DO">Dominican Republic</Option>
            <Option value="EE">Eesti</Option>
            <Option value="SV">El Salvador</Option>
            <Option value="GF">Guyane Française</Option>
            <Option value="FJ">Fiji</Option>
            <Option value="FI">Suomi</Option>
            <Option value="FK">Falkland Islands</Option>
            <Option value="FO">Føroyar</Option>
            <Option value="GM">The Gambia</Option>
            <Option value="GH">Ghana</Option>
            <Option value="GI">Gibraltar</Option>
            <Option value="DE">Deutschland</Option>
            <Option value="GP">Guadeloupe</Option>
            <Option value="GN">Guinea</Option>
            <Option value="HN">Honduras</Option>
            <Option value="IS">Ísland</Option>
            <Option value="HT">Haiti</Option>
            <Option value="HU">Magyarország</Option>
            <Option value="IO">British Indian Ocean Territory</Option>
            <Option value="IT">Italia</Option>
            <Option value="CI">Côte d´Ivoire</Option>
            <Option value="JM">Jamaica</Option>
            <Option value="KP">North Korea</Option>
            <Option value="JO">الأردن</Option>
            <Option value="KZ">Kazakhstan</Option>
            <Option value="LB">لبنان</Option>
            <Option value="KR">한국</Option>
            <Option value="KW">الكويت</Option>
            <Option value="LA">Laos</Option>
            <Option value="LR">Liberia</Option>
            <Option value="LS">Lesotho</Option>
            <Option value="MG">Madagascar</Option>
            <Option value="LY">Libya</Option>
            <Option value="HR">Hrvatska</Option>
            <Option value="MW">Malawi</Option>
            <Option value="ML">Mali</Option>
            <Option value="ID">Republik Indonesia</Option>
            <Option value="MA">المغرب</Option>
            <Option value="MT">Malta</Option>
            <Option value="MU">Mauritius</Option>
            <Option value="MY">Malaysia</Option>
            <Option value="NC">New Caledonia</Option>
            <Option value="VU">Vanuatu</Option>
            <Option value="NL">Nederland</Option>
            <Option value="NP">Nepal</Option>
            <Option value="SR">Suriname</Option>
            <Option value="NZ">New Zealand</Option>
            <Option value="NG">Nigeria</Option>
            <Option value="PN">Pitcairn Islands</Option>
            <Option value="IR">Iran</Option>
            <Option value="IN">India</Option>
            <Option value="IL">Israel</Option>
            <Option value="IQ">Iraq</Option>
            <Option value="JP">日本</Option>
            <Option value="KE">Kenya</Option>
            <Option value="KI">Kiribati</Option>
            <Option value="CX">Christmas Island</Option>
            <Option value="KG">Kyrgyzstan</Option>
            <Option value="LT">Lietuva</Option>
            <Option value="SK">Slovenská</Option>
            <Option value="LI">Liechtenstein</Option>
            <Option value="LV">Latvija</Option>
            <Option value="LU">Luxembourg</Option>
            <Option value="MD">Moldova</Option>
            <Option value="MN">Mongolia</Option>
            <Option value="MQ">Martinique</Option>
            <Option value="YT">Mayotte</Option>
            <Option value="MS">Montserrat</Option>
            <Option value="MK">Macedonia</Option>
            <Option value="MC">Monaco</Option>
            <Option value="MV">Maldives</Option>
            <Option value="MR">Mauritania</Option>
            <Option value="NF">Norfolk Island</Option>
            <Option value="OM">عمان</Option>
            <Option value="MX">México</Option>
            <Option value="PK">Pakistan</Option>
            <Option value="MZ">Mozambique</Option>
            <Option value="PA">Panamá</Option>
            <Option value="NU">Niue</Option>
            <Option value="PG">Papua New Guinea</Option>
            <Option value="NE">Niger</Option>
            <Option value="NO">Norge</Option>
            <Option value="QA">قطر</Option>
            <Option value="NR">Nauru</Option>
            <Option value="PH">Philippines</Option>
            <Option value="NI">Nicaragua</Option>
            <Option value="PY">Paraguay</Option>
            <Option value="SA">السعودية</Option>
            <Option value="PE">Peru</Option>
            <Option value="PL">Polska</Option>
            <Option value="XX">Disputed Territory</Option>
            <Option value="PW">Palau</Option>
            <Option value="MH">Marshall Islands</Option>
            <Option value="RE">La Réunion</Option>
            <Option value="RU">Russia</Option>
            <Option value="PR">Puerto Rico</Option>
            <Option value="PM">Saint Pierre and Miquelon</Option>
            <Option value="XS">Spratly Islands</Option>
            <Option value="PT">Portugal</Option>
            <Option value="GW">Guinea-Bissau</Option>
            <Option value="RO">România</Option>
            <Option value="RW">Rwanda</Option>
            <Option value="KN">Saint Kitts and Nevis</Option>
            <Option value="SN">Sénégal</Option>
            <Option value="SM">San Marino</Option>
            <Option value="LC">St. Lucia</Option>
            <Option value="GS">South Georgia and the South Sandwich Islands</Option>
            <Option value="TK">Tokelau</Option>
            <Option value="SH">St Helena Ascension and Tristan da Cunha</Option>
            <Option value="TN">Tunisia</Option>
            <Option value="SL">Sierra Leone</Option>
            <Option value="ES">España</Option>
            <Option value="SC">Seychelles</Option>
            <Option value="SI">Slovenija</Option>
            <Option value="SO">Somalia</Option>
            <Option value="CH">Schweiz</Option>
            <Option value="TJ">Tajikistan</Option>
            <Option value="SD">Sudan</Option>
            <Option value="SY">Syria</Option>
            <Option value="TT">Trinidad and Tobago</Option>
            <Option value="TC">Turks and Caicos Islands</Option>
            <Option value="TO">Tonga</Option>
            <Option value="ZA">South Africa</Option>
            <Option value="TG">Togo</Option>
            <Option value="TR">Turkey</Option>
            <Option value="SG">Singapore</Option>
            <Option value="SE">Sverige</Option>
            <Option value="TZ">Tanzania</Option>
            <Option value="TH">Thailand</Option>
            <Option value="GB">United Kingdom</Option>
            <Option value="US">United States</Option>
            <Option value="UY">Uruguay</Option>
            <Option value="VG">British Virgin Islands</Option>
            <Option value="VI">US Virgin Islands</Option>
            <Option value="NA">Namibia</Option>
            <Option value="WF">Wallis and Futuna</Option>
            <Option value="UA">Ukrayina</Option>
            <Option value="VE">Venezuela</Option>
            <Option value="VA">Città del Vaticano</Option>
            <Option value="XE">Iraq-Saudi Arabia Neutral Zone</Option>
            <Option value="ZM">Zambia</Option>
            <Option value="WS">Samoa</Option>
            <Option value="ST">Sao Tome and Principe</Option>
            <Option value="VC">Saint Vincent and the Grenadines</Option>
            <Option value="TL">Timor-Leste</Option>
            <Option value="TV">Tuvalu</Option>
            <Option value="TM">Turkmenistan</Option>
            <Option value="UG">Uganda</Option>
            <Option value="BF">Burkina Faso</Option>
            <Option value="UZ">Uzbekistan</Option>
            <Option value="VN">Vietnam</Option>
            <Option value="SZ">Swaziland</Option>
            <Option value="EH">Western Sahara</Option>
            <Option value="XD">United Nations Neutral Zone</Option>
            <Option value="YE">Yemen</Option>
            <Option value="ZW">Zimbabwe</Option>
            <Option value="CY">Cyprus</Option>
            <Option value="UM">United States Minor Outlying Islands</Option>
            <Option value="HM">Heard Island and McDonald Islands</Option>
            <Option value="SJ">Svalbard and Jan Mayen</Option>
            <Option value="AQ">Antarctica</Option>
            <Option value="TF">French Southern Territories</Option>
            <Option value="PS">Palestine</Option>
            <Option value="BV">Bouvet Island</Option>
            <Option value="MF">Saint-Martin</Option>
            <Option value="BL">Saint-Barthélemy</Option>
            <Option value="BQ">Bonaire Saint Eustatius and Saba</Option>
            <Option value="SS">The Republic of South Sudan</Option>
            <Option value="CW">Curaçao</Option>
            <Option value="SX">Sint Maarten</Option>
            <Option value="DE">德国</Option>
          </Select>
        </FormItem>
        <FormItem label="省份" prop="state">
          <Input v-model="formData.state" placeholder="请输入省份"></Input>
        </FormItem>
        <FormItem label="城市" prop="city">
          <Input v-model="formData.city" placeholder="请输入城市"></Input>
        </FormItem>
        <FormItem label="注册地址" prop="registered_address_line1">
          <Input
            v-model="formData.registered_address_line1"
            placeholder="请输入注册地址"
          ></Input>
        </FormItem>
        <FormItem label="邮政编号" prop="postal_code">
          <Input v-model="formData.postal_code" placeholder="请输入邮政编号"></Input>
        </FormItem>
        <FormItem label="企业电话" prop="organization_phone">
          <Input
            v-model="formData.organization_phone"
            placeholder="请输入企业电话"
          ></Input>
        </FormItem>
        <FormItem label="联系人" prop="contact_name">
          <Input v-model="formData.contact_name" placeholder="请输入联系人"></Input>
        </FormItem>
        <FormItem label="职位" prop="contact_title">
          <Input v-model="formData.contact_title" placeholder="请输入职位"></Input>
        </FormItem>
        <FormItem label="联系人电话" prop="contact_phone">
          <Input v-model="formData.contact_phone" placeholder="请输入联系人电话"></Input>
        </FormItem>
      </Form>
      <div
        class="drawer-footer"
        style="text-align: right; border-top: 1px solid #e8e8e8; padding: 10px 16px"
      >
        <Button type="error" style="margin-right: 8px" @click="drawerResetBtn"
          >重置</Button
        >
        <Button type="primary" @click="drawerCheckBtn">确定</Button>
      </div>
    </Drawer>
  </div>
</template>

<script setup>
import { companyDetailApi, companySaveApi } from "@/api/personalCenter";
import {
  ref,
  reactive,
  onMounted,
  watch,
  getCurrentInstance,
  defineOptions,
  defineEmits,
} from "vue";
import { useStore } from "vuex";
import { Message } from "view-ui-plus";
import { log } from "mathjs";
const store = useStore();
const { proxy } = getCurrentInstance(); // 获取组件实例
let isShowDrawer = ref(false);
let drawerTitle = ref("");
let formData = reactive({
  organization: "",
  organization_unit: "",
  serial_no: "",
  date_of_incorporation: new Date(),
  country: "",
  state: "",
  city: "",
  registered_address_line1: "",
  postal_code: "",
  organization_phone: "",
  contact_name: "",
  contact_title: "",
  contact_phone: "",
});
const emit = defineEmits(["refresh"]);

const ruleInline = {
  organization: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
  organization_unit: [{ required: true, message: "请输入部门", trigger: "blur" }],
  serial_no: [{ required: true, message: "请输入三证合一号", trigger: "blur" }],
  date_of_incorporation: [
    { required: true, message: "请输入成立时间", trigger: "blur,change", pattern: /.+/ },
  ],
  country: [{ required: true, message: "请选择国家", trigger: "blur" }],
  state: [{ required: true, message: "请输入省份", trigger: "blur" }],
  city: [{ required: true, message: "请输入城市", trigger: "blur" }],
  registered_address_line1: [
    { required: true, message: "请输入注册地址", trigger: "blur" },
  ],
  postal_code: [{ required: true, message: "请输入邮政编号", trigger: "blur" }],
  organization_phone: [{ required: true, message: "请输入企业电话", trigger: "blur" }],
  contact_name: [{ required: true, message: "请输入联系人", trigger: "blur" }],
  contact_title: [{ required: true, message: "请输入职位", trigger: "blur" }],
  contact_phone: [{ required: true, message: "请输入联系人电话", trigger: "blur" }],
};
let drawerWidth = ref("300");

const getCompanyDetail = (data) => {
  companyDetailApi(data).then((res) => {
    if (res.status == 200) {
      // formData = res.data.info
      Object.assign(formData, res.data.info);
    }
  });
};
const companySave = (data) => {
  companySaveApi(data)
    .then((res) => {
      if (res.status == 200) {
        Message.success(res.msg);
        emit("refresh");
        isShowDrawer.value = false;
      }
    })
    .catch((res) => {
      Message.error(res.msg);
    });
};
// 添加公司
const addCompany = () => {
  // proxy.$refs.companyForm.resetFields();
  isShowDrawer.value = true;
  // console.log(formData.date_of_incorporation);
  drawerTitle.value = "添加公司信息";
};
// 编辑公司按钮
const editCompany = (id) => {
  // proxy.$refs.companyForm.resetFields();
  isShowDrawer.value = true;
  drawerTitle.value = "修改公司信息";
  getCompanyDetail({ id: id });
};
const dateChange = (e) => {
  formData.date_of_incorporation = e;
};
// 编辑抽屉 重置按钮
const drawerResetBtn = () => {
  proxy.$refs.companyForm.resetFields();
};

// 编辑抽屉 确定按钮
const drawerCheckBtn = () => {
  proxy.$refs.companyForm.validate((valid) => {
    if (valid) {
      companySave(formData);
    } else {
      console.log(formData);
      return false;
    }
  });
};
watch(() => {
  if (store.state.media.isDesktop == true) {
    drawerWidth.value = "480px";
  } else {
    drawerWidth.value = "100%";
  }
});
defineOptions({
  name: "CompanyDetailDrawer",
});

defineExpose({
  addCompany,
  editCompany,
  companyForm: proxy.$refs.companyForm,
});
</script>

<style lang="less" scoped></style>
